$dark-eggplant: #1D1F45;
$eggplant: #6C6C84;
$light-eggplant: #D2D2DA;
$silver: #F1F2F4;
$light-silver: #F8F8F9;
$dark-ocean: #26828C;
$ocean: #3EC0CD;
$light-ocean: #CCECF2;
$lightest-ocean: #F1FAF9;
$charcoal: #262626;
$cherry: #E91627;

:export {
    darkeggplant: $dark-eggplant;
    eggplant: $eggplant;
    lighteggplant: $light-eggplant;
    silver: $silver;
    lightsilver: $light-silver;
    darkocean: $dark-ocean;
    ocean: $ocean;
    lightocean: $light-ocean;
    lightestocean: $lightest-ocean;
    charcoal: $charcoal;
    cherry: $cherry;
}